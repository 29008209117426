























import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class IndexOverview extends Vue {

  get workspace() {
    return this.$store.state.workspace;
  }

}
